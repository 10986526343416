var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.listType === 'read')?_c('el-tooltip',{attrs:{"content":"移至未讀列表","placement":"top"}},[_c('span',{on:{"click":() => { _vm.setUnread(_vm.id); }}},[_c('i',{staticClass:"far fa-envelope action-icon"})])]):_vm._e(),(_vm.listType === 'unread' || !_vm.currentStatusRead)?_c('el-tooltip',{attrs:{"content":"移至已讀列表","placement":"top"}},[_c('span',{on:{"click":() => {
          if (_vm.listType === 'unread') {
            _vm.setRead(_vm.id);
          } else {
            if (_vm.currentStatusRead) return;
            _vm.setStatus({ id: _vm.id, status: 1 });
          }
        }}},[_c('i',{staticClass:"far fa-envelope-open action-icon"})])]):_vm._e(),(!_vm.currentStatusArchive)?_c('el-tooltip',{attrs:{"content":"移至封存","placement":"top"}},[_c('span',{on:{"click":() => {
          if (_vm.currentStatusArchive) return;
          _vm.setStatus({ id: _vm.id, status: 2 });
        }}},[_c('i',{staticClass:"fa fa-archive action-icon"})])]):_vm._e(),(!_vm.currentStatusCancel)?_c('el-tooltip',{attrs:{"content":"取消報名表","placement":"top"}},[_c('span',{on:{"click":() => {
          if (_vm.currentStatusCancel) return;
          _vm.setStatus({ id: _vm.id, status: 3 });
        }}},[_c('i',{staticClass:"fa fa-ban action-icon",attrs:{"aria-hidden":"true"}})])]):_vm._e(),(!_vm.currentStatusTrash)?_c('el-tooltip',{attrs:{"content":"移至垃圾桶","placement":"top"}},[_c('span',{on:{"click":() => {
          if (_vm.currentStatusTrash) return;
          _vm.setStatus({ id: _vm.id, status: 4 });
        }}},[_c('i',{staticClass:"fa fa-trash action-icon warning-icon",attrs:{"aria-hidden":"true"}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }