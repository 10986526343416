<template>
  <div>
    <LogDialog
      v-if="addEnrollment"
      :id="addEnrollment.id"
      :isLogDialogVisible="isLogDialogVisible"
      @reload="$emit('getEnrollment')"
      @toggleLogDialog="toggleLogDialog"
    />
    <PlacementTestDialog
      v-if="addEnrollment"
      :id="addEnrollment.id"
      :isAssignTestDialogVisible="isAssignTestDialogVisible"
      :enrolled_session="addEnrollment"
      @reload="$emit('getEnrollment')"
      @toggleAssignTestDialog="toggleAssignTestDialog"
    />
    <ContractDialog
      v-if="addEnrollment"
      :id="String(addEnrollment.id)"
      :enrolled_session="addEnrollment"
      :isContractDialogVisible="isContractDialogVisible"
      :hasContract="addEnrollment.contract_url !== null"
      :hasSignedContract="addEnrollment.signed_contract_url !== null"
      @toggleContractDialog="toggleContractDialog"
      @reload="$emit('getEnrollment')"
    />
    <div>
      <div class="listActions">
        <div v-if="listType === 'read'" class="dropdown">
          <h4 class="dropdown-toggle pointer" data-toggle="dropdown">
            {{ getReadStatusText(currentStatus - 1) }}
          </h4>
          <div class="dropdown-menu" style="z-index:2001; font-size:16px">
            <div class="pointer">
              <div class="dropdown-item" @click="() => statusChangeButton(1)">
                <i class="far fa-envelope-open" />
                {{ getReadStatusText(0) }}
              </div>
              <div class="dropdown-item" @click="() => statusChangeButton(2)">
                <i class="fa fa-archive" />
                {{ getReadStatusText(1) }}
              </div>
              <div class="dropdown-item" @click="() => statusChangeButton(3)">
                <i class="fa fa-ban" />
                {{ getReadStatusText(2) }}
              </div>
              <div class="dropdown-item" @click="() => statusChangeButton(4)">
                <i class="fa fa-trash" />
                {{ getReadStatusText(3) }}
              </div>
            </div>
          </div>
        </div>
        <h4 v-else class="ivy-unread">
          {{ $t("enrollment.list.readStatusList")[0] }}
          <el-badge v-if="unreadTotal" :value="unreadTotal" class="mark" />
        </h4>
        <div v-if="hasSelectedEnrollement" class="statusIcons">
          <el-tooltip
            v-if="listType === 'read'"
            content="移至未讀列表"
            placement="top"
          >
            <span class="action-icon" @click="batchSetUnread">
              <i class="far fa-envelope" />
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="!currentStatusRead"
            content="移至已讀列表"
            placement="top"
          >
            <span
              class="action-icon"
              @click="listType === 'read' ? batchSetStatus(1) : batchSetRead()"
            >
              <i class="far fa-envelope-open" />
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="!currentStatusArchive"
            content="移至封存"
            placement="top"
          >
            <span class="action-icon" @click="batchSetStatus(2)">
              <i class="fa fa-archive" />
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="!currentStatusCancel"
            content="取消報名表"
            placement="top"
          >
            <span class="action-icon" @click="batchSetStatus(3)">
              <i class="fa fa-ban" />
            </span>
          </el-tooltip>
          <el-tooltip
            v-if="!currentStatusTrash"
            content="移至垃圾桶"
            placement="top"
          >
            <span class="warning-icon" @click="batchSetStatus(4)">
              <i class="fa fa-trash" />
            </span>
          </el-tooltip>
        </div>
      </div>
    </div>
    <el-table
      v-if="enrollmentList.length > 0"
      :data="enrollmentList"
      height="500px"
      stripe
    >
      <el-table-column align="center" width="60px">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.selected" />
        </template>
      </el-table-column>
      <!-- ===== 查看 ===== -->
      <el-table-column align="center" width="70px">
        <template slot-scope="scope">
          <el-tooltip content="查看報名表" placement="top">
            <router-link
              :to="{ name: 'EnrollmentDetail', params: { id: scope.row.id } }"
            >
              <i class="fa fa-eye action-icon" aria-hidden="true" />
            </router-link>
          </el-tooltip>
        </template>
      </el-table-column>

      <!-- ===== 狀態：未讀／已讀／取消／封存／刪除 ===== -->
      <el-table-column align="center" width="170px">
        <template slot-scope="scope">
          <EnrolledStatusButtons
            :id="scope.row.id"
            :status="scope.row.status"
            :setStatus="setStatus"
            :setRead="setRead"
            :setUnread="setUnread"
            :listType="listType"
          />
        </template>
      </el-table-column>

      <el-table-column type="index" align="center" label="#" width="60px" />

      <!-- ===== 報名課程 ===== -->
      <el-table-column :label="$t('enrollment.list.course')" width="260">
        <template slot-scope="scope">
          <div style="white-space: nowrap;">
            <!-- 明確的梯次 -->
            <el-button
              v-if="scope.row.course_session_title"
              @click="$emit('filterSession', scope.row.course_session_id)"
              size="small"
              class="ivy-course"
            >
              {{
                helper.displayI18nText(
                  $i18n.locale,
                  scope.row.course_session_title
                )
              }}
            </el-button>
            <!-- 想上的課 -->
            <div
              v-else
              :key="course.id"
              v-for="(course, index) in scope.row.interested_courses"
              style="display: inline"
            >
              <el-button
                @click="
                  routerPush({
                    name: 'EnrollmentDetail',
                    params: { id: scope.row.id }
                  })
                "
                :key="index"
                type="text"
                class="ivy-course"
              >
                {{ course }}
              </el-button>
              <span
                :key="`br-${index}`"
                v-if="index < scope.row.interested_courses.length - 1"
                >,</span
              >
            </div>
            <!-- 家教 -->
            <el-button
              v-if="scope.row.custom_online"
              type="text"
              class="ivy-course"
            >
              {{ `-${scope.row.custom_online}` }}
            </el-button>
            <el-button
              v-if="scope.row.custom_offline"
              type="text"
              class="ivy-course"
            >
              {{ `-${scope.row.custom_offline}` }}
            </el-button>
          </div>
        </template>
      </el-table-column>

      <!-- ===== 學生 ===== -->
      <el-table-column label="學生">
        <!-- 學生姓名 -->
        <el-table-column
          prop="first_name"
          :label="$t('enrollment.list.name')"
          width="120"
        >
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.student_user_id }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.student_first_name,
                  scope.row.student_last_name
                )
              }}
            </router-link>
          </template>
        </el-table-column>
        <!-- 學生電話 -->
        <el-table-column
          prop="student_phone"
          :label="$t('enrollment.step1.phone')"
          width="120"
        ></el-table-column>
        <!-- 學生 email -->
        <el-table-column
          prop="student_email"
          :label="$t('enrollment.step1.email')"
          width="200"
        ></el-table-column>
      </el-table-column>

      <!-- ===== 家長 ===== -->
      <el-table-column label="家長">
        <!-- 家長姓名 -->
        <el-table-column
          prop="first_name"
          :label="$t('enrollment.list.name')"
          width="120"
        >
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.parent_user_id }
              }"
            >
              {{
                userMethods.displayName(
                  scope.row.parent_first_name,
                  scope.row.parent_last_name
                )
              }}
            </router-link>
          </template>
        </el-table-column>
        <!-- 家長電話 -->
        <el-table-column
          prop="parent_phone"
          :label="$t('enrollment.step1.phone')"
          width="120"
        ></el-table-column>
        <!-- 家長 email -->
        <el-table-column
          prop="parent_email"
          :label="$t('enrollment.step1.email')"
          width="200"
        ></el-table-column>
      </el-table-column>

      <!-- ===== 報名日期 ===== -->
      <el-table-column
        prop="created_at"
        :label="$t('enrollment.list.date')"
        align="center"
        width="140px"
      >
        <template slot-scope="scope">
          <!-- <CurrentStep :currentStep="scope.row.current_step" /> -->
          {{instant.formatGoogleTime(scope.row.created_at)}}
        </template>
      </el-table-column>

      <!-- ===== 步驟 ===== -->
      <el-table-column
        :label="$t('enrollment.list.step')"
        align="center"
        width="80px"
      >
        <template slot="header">
          <i class="el-icon-s-order"></i>
        </template>
        <template slot-scope="scope">
          <CurrentStep :currentStep="scope.row.current_step" />
        </template>
      </el-table-column>

      <!-- ===== Log===== -->
      <el-table-column v-if="listType === 'read'" align="center" width="60px">
        <template slot="header">
          <el-tooltip
            :content="$t('enrollment.detail.log')"
            placement="top"
            popper-class="tooltipColor"
          >
            <i class="el-icon-edit" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.count_contact > 0"
            :content="scope.row.latest_contact.content"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{ name: 'EnrollmentDetail', params: { id: scope.row.id } }"
            >
              {{ scope.row.count_contact }}
            </router-link>
          </el-tooltip>
          <span
            v-else
            class="action-icon"
            @click="() => toggleLogDialog(true, scope.row)"
          >
            <i class="el-icon-plus" />
          </span>
        </template>
      </el-table-column>

      <!-- ===== 合約 ===== -->
      <el-table-column v-if="listType === 'read'" align="center" width="200px">
        <template slot="header">
          <el-tooltip
            :content="$t('enrollment.detail.contract')"
            placement="top"
            popper-class="tooltipColor"
          >
            <span>
              <i class="fas fa-file-signature" />
            </span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <ContractStatus
            :contractCreatedAt="scope.row.contract_created_at"
            :contractSignedAt="scope.row.contract_signed_at"
            :hasContract="scope.row.contract_url !== null"
            :hasSignedContract="scope.row.signed_contract_url !== null"
            @openContractDialog="
              () => {
                toggleContractDialog(true, scope.row);
              }
            "
          />
        </template>
      </el-table-column>

      <!-- ===== 付款===== -->
      <el-table-column v-if="listType === 'read'" align="center" width="70px">
        <template slot="header">
          <el-tooltip
            :content="$t('enrollment.list.paymentStatus')"
            placement="top"
            popper-class="tooltipColor"
          >
            <i class="el-icon-money" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <template v-if="scope.row.payment_status">
            <template v-if="scope.row.payment_status === 1">
              <span class="text-success"><i class="fas fa-check"></i></span>
            </template>
            <template v-else>
              <router-link
                v-if="scope.row.order"
                :to="{
                  name: 'billView',
                  params: { billId: scope.row.order.id }
                }"
              >
                <i class="fas fa-check"></i>
              </router-link>
              <router-link
                v-else-if="scope.row.receivable_order"
                :to="{
                  name: 'ReceivableView',
                  params: { receivableId: scope.row.receivable_order.id }
                }"
              >
                <i class="fas fa-check"></i>
              </router-link>
            </template>
          </template>
          <router-link
            v-else-if="scope.row.receivable_order"
            :to="{
              name: 'ReceivableView',
              params: { receivableId: scope.row.receivable_order.id }
            }"
          >
            <span style="color: red">分期付款中</span>
          </router-link>
          <!-- <router-link
            v-else
            :to="{
              name: 'createReceivableWithEnrollment',
              params: { id: scope.row.id }
            }"
          >
            <i class="el-icon-plus" />
          </router-link> -->
          <router-link
            v-else
            :to="{
              name: 'createBillWithEnrollment',
              params: { id: scope.row.id }
            }"
          >
            <i class="el-icon-plus" />
          </router-link>
        </template>
      </el-table-column>

      <!-- ===== 分班考===== -->
      <el-table-column v-if="listType === 'read'" align="center" width="300px">
        <template slot="header">
          <el-tooltip
            :content="$t('enrollment.detail.placementTest')"
            placement="top"
            popper-class="tooltipColor"
          >
            <i class="el-icon-tickets" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <ExamStatus
            :enrollment="scope.row"
            :hasPlacementTest="{
              exam_id: scope.row.entry_test_paper_id,
              user_exam_id: scope.row.entry_test_result_id,
              user_exam: scope.row.userExam,
              entry_test_type: scope.row.entry_test_paper_type,
              entry_test_date: scope.row.entry_test_date,
              exam: scope.row.exam
                ? scope.row.exam
                : scope.row.userExam
                  ? scope.row.userExam.exam
                  : null,
              score: scope.row.score,
              student_id: scope.row.student_user_id
            }"
            @toggleAssignTestDialog="
              () => {
                toggleAssignTestDialog(true, scope.row);
              }
            "
          />
        </template>
      </el-table-column>

      <!-- ===== 分班===== -->
      <el-table-column v-if="listType === 'read'" align="center" width="200px">
        <template slot="header">
          <el-tooltip
            :content="$t('enrollment.detail.class')"
            placement="top"
            popper-class="tooltipColor"
          >
            <i class="el-icon-folder" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{ name: 'EnrollmentDetail', params: { id: scope.row.id } }"
          >
            <div v-if="scope.row.arrange_status">
              <span
                :key="sessionClass.id"
                v-for="(sessionClass, index) in filterNotNull(
                  scope.row.session_classes
                )"
              >
                {{ sessionClass.title
                }}<span
                  v-if="
                    index !==
                      filterNotNull(scope.row.session_classes).length - 1
                  "
                  >,
                </span>
              </span>
            </div>
            <i v-else class="fas fa-edit"></i>
          </router-link>
        </template>
      </el-table-column>

      <!-- ===== 聯絡人（填表人 or 家長） ===== -->
      <el-table-column :label="$t('enrollment.list.contact')">
        <!-- 聯絡人姓名 -->
        <el-table-column
          prop="first_name"
          :label="$t('enrollment.list.name')"
          width="120"
        >
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'EnrollmentDetail', params: { id: scope.row.id } }"
            >
              {{ scope.row.first_name }}
            </router-link>
          </template>
        </el-table-column>

        <!-- 聯絡人與學生關係 -->
        <el-table-column
          prop="role"
          :label="$t('enrollment.list.role')"
        ></el-table-column>

        <!-- 聯絡人 email -->
        <el-table-column
          prop="email"
          :label="$t('enrollment.step1.email')"
          width="200"
        ></el-table-column>

        <!-- 聯絡人電話 -->
        <el-table-column
          prop="phone"
          :label="$t('enrollment.step1.phone')"
          width="120"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import "@/assets/scss/style.scss";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import LogDialog from "@/components/enrollment/LogDialog";
import PlacementTestDialog from "@/components/enrollment/PlacementTestDialog";
import ContractDialog from "@/components/enrollment/ContractDialog";
import ExamStatus from "@/views/home/enrollment/steps/ExamStatus";
import ContractStatus from "@/views/home/enrollment/steps/ContractStatus";
import CurrentStep from "@/components/enrollment/CurrentStep";
import EnrolledStatusButtons from "@/components/enrollment/EnrolledStatusButtons";
import { helper, user, instant } from "@ivy-way/material";

import enrollmentApi from "@/apis/enrollment";

export default {
  components: {
    LogDialog,
    PlacementTestDialog,
    ExamStatus,
    ContractStatus,
    CurrentStep,
    EnrolledStatusButtons,
    ContractDialog
  },
  props: ["enrollmentListProp", "listType", "formStatus", "unreadTotal"],
  data() {
    return {
      enrollmentList: [],
      isAssignTestDialogVisible: false,
      isLogDialogVisible: false,
      isContractDialogVisible: false,
      addEnrollment: null
    };
  },
  computed: {
    instant() {
      return instant;
    },
    helper() {
      return helper;
    },
    userMethods() {
      return user;
    },
    roleList() {
      return this.$t("enrollment.step1.roleList");
    },
    hasSelectedEnrollement() {
      return this.enrollmentList.some(({ selected }) => selected);
    },
    currentStatus() {
      return this.formStatus ? Number(this.formStatus) : 1;
    },
    currentStatusRead() {
      return this.currentStatus === 1;
    },
    currentStatusArchive() {
      return this.currentStatus === 2;
    },
    currentStatusCancel() {
      return this.currentStatus === 3;
    },
    currentStatusTrash() {
      return this.currentStatus === 4;
    }
  },
  watch: {
    enrollmentListProp(enrollmentListProp) {
      this.formatEnrollment(enrollmentListProp);
    }
  },

  created() {
    this.formatEnrollment(this.enrollmentListProp);
  },

  methods: {
    getReadStatusText(status) {
      if (status === 0) {
        return this.$t("enrollment.list.readStatusList")[1];
      } else {
        return this.$t("enrollment.list.statusList")[status];
      }
    },

    statusChangeButton(status) {
      this.$router.push({
        name: "EnrollmentList",
        query: {
          ...this.$route.query,
          status
        }
      });
    },
    filterNotNull(sessionClasses) {
      return sessionClasses.filter(sessionClass => sessionClass);
    },
    async setStatus(data) {
      await enrollmentApi.setStatus(data);

      this.$emit("onStatusChanged");
    },
    async setRead(enrollmentId) {
      await enrollmentApi.read({ id: enrollmentId, is_read: 1 });

      this.$emit("onStatusChanged");
    },
    async setUnread(enrollmentId) {
      await enrollmentApi.markUnread(enrollmentId);

      this.$emit("onStatusChanged");
    },
    async batchSetStatus(status) {
      try {
        const enrollmentIds = this.enrollmentList
          .filter(({ selected }) => selected)
          .map(({ id }) => id);
        await enrollmentApi.batchUpdateEnrollmentStatus(enrollmentIds, status);
        this.$emit("onStatusChanged");
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async batchSetRead() {
      try {
        const batchSetReadPromise = this.enrollmentList
          .filter(({ selected }) => selected)
          .map(({ id }) => enrollmentApi.read({ id, is_read: 1 }));

        await Promise.all(batchSetReadPromise);
        this.$emit("onStatusChanged");
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async batchSetUnread() {
      try {
        const batchSetUnreadPromise = this.enrollmentList
          .filter(({ selected }) => selected)
          .map(({ id }) => enrollmentApi.markUnread(id));

        await Promise.all(batchSetUnreadPromise);
        this.$emit("onStatusChanged");
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    // FIXME: 建議另外開 emit 事件，onStatusChanged 是狀態改變的觸發，後續處理方式可能不同
    updateOrder() {
      this.$emit("onStatusChanged");
    },
    formatEnrollment(enrollmentListProp) {
      const setContactPerson = enrollment => {
        if (enrollment.current_step > 1 && enrollment.parent_email != null) {
          return {
            ...enrollment,
            email: enrollment.parent_email,
            phone: enrollment.parent_phone,
            first_name: this.userMethods.displayName(
              enrollment.parent_first_name,
              enrollment.parent_last_name
            ),
            role: this.roleList[1]
          };
        } else {
          return enrollment;
        }
      };

      this.enrollmentList = enrollmentListProp
        .map(setContactPerson)
        .map(enrollment => ({ ...enrollment, selected: false }));
    },
    toggleLogDialog(visible, enrollment) {
      this.addEnrollment = enrollment;
      this.isLogDialogVisible = visible;
    },
    toggleAssignTestDialog(visible, enrollment) {
      this.addEnrollment = enrollment;
      this.isAssignTestDialogVisible = visible;
    },
    toggleContractDialog(visible, enrollment) {
      this.addEnrollment = enrollment;
      this.isContractDialogVisible = visible;
    }
  }
};
</script>

<style lang="scss" scoped>
.ivy-course {
  white-space: normal;
  word-break: break-word;
  text-align: left;
}
a.link {
  cursor: pointer;
  color: #eee !important;
}
a.link:hover {
  color: #42a16a !important;
}

.listActions {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.statusIcons {
  display: flex;
  margin-left: 24px;
  padding: 4px 0px;

  span {
    margin: 0px 8px;
  }

  span:hover {
    animation: 0.8s rotate;
  }
  @keyframes rotate {
    0% {
      transform: rotate(25deg);
    }
    10% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(-25deg);
    }
    30% {
      transform: rotate(0deg);
    }
    40% {
      transform: rotate(25deg);
    }
    50% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(-25deg);
    }
    70% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(25deg);
    }
    90% {
      transform: rotate(0deg);
    }
  }
}
</style>
