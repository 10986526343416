<template>
  <div>
    <el-tooltip
      v-if="listType === 'read'"
      content="移至未讀列表"
      placement="top"
    >
      <span
        @click="
          () => { setUnread(id); }
        "
      >
        <i class="far fa-envelope action-icon" />
      </span>
    </el-tooltip>
    <el-tooltip
      v-if="listType === 'unread' || !currentStatusRead"
      content="移至已讀列表"
      placement="top"
    >
      <span
        @click="
          () => {
            if (listType === 'unread') {
              setRead(id);
            } else {
              if (currentStatusRead) return;
              setStatus({ id, status: 1 });
            }
          }
        "
      >
        <i class="far fa-envelope-open action-icon" />
      </span>
    </el-tooltip>
    <el-tooltip v-if="!currentStatusArchive" content="移至封存" placement="top">
      <span
        @click="
          () => {
            if (currentStatusArchive) return;
            setStatus({ id, status: 2 });
          }
        "
      >
        <i class="fa fa-archive action-icon" />
      </span>
    </el-tooltip>
    <el-tooltip v-if="!currentStatusCancel" content="取消報名表" placement="top">
      <span
        @click="
          () => {
            if (currentStatusCancel) return;
            setStatus({ id, status: 3 });
          }
        "
      >
        <i class="fa fa-ban action-icon" aria-hidden="true" />
      </span>
    </el-tooltip>
    <el-tooltip v-if="!currentStatusTrash" content="移至垃圾桶" placement="top">
      <span
        @click="
          () => {
            if (currentStatusTrash) return;
            setStatus({ id, status: 4 });
          }
        "
      >
        <i class="fa fa-trash action-icon warning-icon" aria-hidden="true" />
      </span>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: ["setStatus", "setRead", "setUnread", "batchSetRead", "status", "id", "listType"],
  computed: {
    statusList() {
      return this.$t("enrollment.list.statusList");
    },
    currentStatusRead() {
      return this.status === 1;
    },
    currentStatusArchive() {
      return this.status === 2;
    },
    currentStatusCancel() {
      return this.status === 3;
    },
    currentStatusTrash() {
      return this.status === 4;
    }
  }
};
</script>
