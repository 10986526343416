<template>
  <div class="container-fluid">
    <Breadcrumb />
    <Heading :content="$t('pageTitle.EnrollmentList')"></Heading>
    <hr />
    <div class="headerLinkBtns">
      <router-link :to="{ name: 'EnrollmentList' }">
        <el-button type="primary" icon="el-icon-message">
          Enroll Inbox（報名表信件）
        </el-button>
      </router-link>
      <router-link :to="{ name: 'Arrange' }">
        <el-button>
          Placement（分班管理）
        </el-button>
      </router-link>
      <router-link :to="{ name: 'sessionClassesList' }">
        <el-button>
          Sections List（小班清單）
        </el-button>
      </router-link>
    </div>
    <el-form @submit.prevent.native="getData">
      <SessionList
        :showStudentEnrollCount="true"
        @onSessionChanged="filterSession"
        :activeSessionId="$route.query.sessionId"
      />
      <el-input v-model="form.search" placeholder="Search" style="width:50%" />
      <el-button native-type="submit">Search</el-button>
    </el-form>
    <br />
    <!-- ===== 未讀 ===== -->
    <div v-if="unreadData.total > 0">
      <hr />
      <List
        listType="unread"
        @onStatusChanged="getData"
        @filterSession="filterSession"
        :unreadTotal="unreadData.total"
        :enrollmentListProp="unreadData.data"
      />
      <div class="text-center mt-3 mb-5">
        <el-pagination
          background
          @current-change="getUnreadData"
          @prev-click="getUnreadData"
          @next-click="getUnreadData"
          :page-count="unreadData.last_page"
          :hide-on-single-page="true"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
    <hr />

    <!-- ===== 已讀 ===== -->
    <List
      listType="read"
      :formStatus="form.status"
      @onStatusChanged="getData"
      @filterSession="filterSession"
      :enrollmentListProp="readData.data"
      @getEnrollment="getData"
    />
    <div class="text-center mt-3 mb-5">
      <el-pagination
        background
        @current-change="getReadData"
        @prev-click="getReadData"
        @next-click="getReadData"
        :page-count="readData.last_page"
        :hide-on-single-page="true"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import Breadcrumb from "@/components/Breadcrumb";
import SessionList from "@/components/course/SessionList";
import List from "@/components/enrollment/List";

import enrollmentApi from "@/apis/enrollment";

export default {
  components: {
    Breadcrumb,
    SessionList,
    List
  },

  mixins: [],

  props: [],
  data() {
    return {
      form: {
        course_session_id: 0,
        status: 1,
        search: ""
      },

      unreadData: {
        data: []
      },
      readData: {
        data: []
      }
    };
  },
  watch: {},

  async mounted() {
    this.getData();
  },

  methods: {
    filterSession(sessionId) {
      this.$router.push({
        name: "EnrollmentList",
        query: { sessionId, status: this.form.status }
      });
    },

    getData() {
      if (typeof this.$route.query.sessionId !== "undefined") {
        this.form.course_session_id = this.$route.query.sessionId;
      }
      if (typeof this.$route.query.status !== "undefined") {
        this.form.status = this.$route.query.status;
      }
      Promise.all([this.getUnreadData(1), this.getReadData(1)]);
    },

    async getUnreadData(page) {
      this.unreadData.data = [];

      const req = {
        is_read: 0,
        page: page,
        ...this.form
      };

      if (!req.course_session_id) {
        delete req.course_session_id;
      }

      const enrollmentRes = await enrollmentApi.fetchAll(req);

      const { enrolled_sessions } = enrollmentRes;

      this.unreadData = enrolled_sessions;
    },
    async getReadData(page) {
      this.readData.data = [];

      const req = {
        is_read: 1,
        page: page,
        ...this.form
      };

      if (!req.course_session_id) {
        delete req.course_session_id;
      }

      const enrollmentRes = await enrollmentApi.fetchAll(req);

      const { enrolled_sessions } = enrollmentRes;

      this.readData = enrolled_sessions;
    }
  }
};
</script>

<style lang="scss" scoped>
.headerLinkBtns {
  margin: 20px 0px;

  a {
    margin-right: 20px;
  }
}
</style>
